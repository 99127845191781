export const formatDate = (date) => date ? new Date(date).toLocaleDateString() : '';
export const getFiterDatesLabel = (filterDates, label = 'Dates') => {
    const { from, to } = filterDates;

    if (from && to) {
        return `${formatDate(from)} - ${formatDate(to)}`;
    } else if (from) {
        return `${formatDate(from)} - today`;
    } else if (to) {
        return `Until ${formatDate(to)}`;
    }

    return label;
};

export const calcTZOffset = (timeZone) => {
    const now = new Date();
    const dateInTimezone1 = new Date(
        now.toLocaleString('en-US', { timeZone })
    );
    const dateInTimezone2 = new Date(
        now.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
    );

    return (dateInTimezone1 - dateInTimezone2) / (1000 * 60);
};