import React, { useEffect, useMemo } from 'react';
import { Progress } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { calcTZOffset } from '../utils/dates';
import { getIngestJobs, fetchOrder } from '../slices/orders.slice';

const IngestProgress = ({ timezone = 'UTC' }) => {
    const dispatch = useDispatch();
    const { currentOrder, ingestJobs } = useSelector((state) => state.orders);

    const { playlists_uuids = [], OV_uuid = '' } = currentOrder || {};

    const orderJobs = useMemo(() => {
        return ingestJobs.filter(({ task_parameters, task_infos }) => {
            const matchesUUID =
                playlists_uuids.some(uuid => task_parameters.uri.includes(uuid)) ||
                task_parameters.uri.includes(OV_uuid);

            const jobCreationDate = new Date(task_infos.creation_date);
            const referenceJob = ingestJobs.find(({ task_parameters, task_infos }) => {
                return (
                    (playlists_uuids.some(uuid => task_parameters.uri.includes(uuid)) ||
                        task_parameters.uri.includes(OV_uuid)) &&
                    task_infos.creation_date
                );
            });

            if (!referenceJob) return false;

            const referenceCreationDate = new Date(referenceJob.task_infos.creation_date);
            const timeDifference = Math.abs((jobCreationDate - referenceCreationDate) / 1000);
            const isWithinTimeFrame = timeDifference <= 30;

            return matchesUUID && isWithinTimeFrame;
        });
    }, [ingestJobs, playlists_uuids, OV_uuid]);

    const runningJob = useMemo(() => {
        return orderJobs.find(job => job.state === 'Running');
    }, [orderJobs]);

    const completedSteps = orderJobs.filter(job => job.state === 'Completed').length;
    const currentStep = completedSteps + 1;

    const pendingJobs = orderJobs.filter(job => job.state === 'Pending');

    const calculateETA = (taskInfo) => {
        if (!taskInfo?.finished_date) return 'Unknown';
        const taskFinishedDate = new Date(taskInfo.finished_date);
        const timezoneOffset = calcTZOffset(timezone);
        const adjustedFinishedDate = new Date(taskFinishedDate.getTime() - timezoneOffset * 60 * 1000);
        const now = new Date();
        return Math.max(0, Math.round((adjustedFinishedDate - now) / (1000 * 60))); // Avoid negative ETA
    };

    useEffect(() => {
        let intervalId;

        if (currentOrder?.status === 'transferring-to-mediablock') {
            dispatch(getIngestJobs());

            intervalId = setInterval(() => {
                dispatch(getIngestJobs());
            }, 5000);
        }

        if (orderJobs.length > 0 && orderJobs.every(job => job.state === 'Completed' && currentOrder.status === 'transferring-to-mediablock')) {
            dispatch(fetchOrder(currentOrder._id));
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [currentOrder, dispatch, orderJobs]);

    if (!runningJob && pendingJobs.length === 0) {
        return null;
    }

    const { task_status, task_infos } = runningJob || {};
    const progress = task_status?.total_progress || 0;
    const eta = task_infos ? calculateETA(task_infos) : 'Unknown';

    const renderStepProgress = () => {
        return orderJobs.map((job, index) => {
            const stepNumber = index + 1;
            const isCompleted = job.state === 'Completed';
            const isCurrent = stepNumber === currentStep;

            return (
                <div
                    key={stepNumber}
                    className={`d-flex flex-column align-items-center ${isCurrent ? 'text-primary' : isCompleted ? '' : 'text-secondary'}`}
                    style={{ flex: 1, textAlign: 'center' }}
                >
                    <span
                        className={`fw-bold ${isCurrent ? 'text-white' : isCompleted ? '' : 'text-white-50'}`}
                        style={{fontSize: '1rem'}}>
                        STEP {stepNumber}
                        {isCurrent && <span className="fas fa-cog fa-spin ms-2 text-primary"/>}
                        {isCompleted && <span className="fas fa-check ms-2 text-primary"/>}
                    </span>
                    <div
                        className={`pill mt-1 ${isCompleted ? 'bg-primary' : isCurrent ? 'bg-primary' : 'bg-dark'}`}
                        style={{
                            width: '80%',
                            height: '3px',
                            transition: 'background-color 0.3s ease'
                        }}
                    />
                </div>
            );
        });
    };

    return (
        <div className="text-white border border-primary p-4 my-3 shadow-lg my-4 mb-5">
            <div className="d-flex justify-content-between mb-3">
                {renderStepProgress()}
            </div>

            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <span className="fas fa-tasks text-success me-2" style={{fontSize: '1.5rem'}}/>
                    <span className="text-light fw-bold" style={{fontSize: '1.1rem'}}>
                        Transfer in progress...
                    </span>
                </div>
                <span className="fw-bold text-white pulse-effect" style={{fontSize: '1.2rem'}}>
                    {progress}%
                </span>
            </div>

            <Progress
                value={progress}
                animated
                color="success"
                style={{height: '12px', marginTop: '10px', backgroundColor: '#222'}}
                aria-label="Ingest progress"
            />

            <div className="d-flex justify-content-end gap-3 mt-3">
                <div className="d-flex align-items-center">
                    <span className="fas fa-clock text-primary me-2" />
                    <span className="text-light">ETA:</span>
                </div>
                <span className="fw-bold text-white" style={{fontSize: '1.2rem'}}>
                    {eta} minutes left
                </span>
            </div>
        </div>
    );
};

export default IngestProgress;
